import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'webcat-footer',
  templateUrl: 'webcat-footer.html',
  styleUrls: ['webcat-footer.scss']
})
export class WebcatFooterComponent implements OnInit, OnDestroy, AfterViewInit {
  //#region Queries
  //#endregion

  //#region Properties
  //#endregion

  //#region Fields

  socialNetworks: { url: string, icon: string }[] = [];

  //#endregion

  //#region Constructor

  constructor(
  ) {
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  //#endregion

  //#region Helpers
  //#endregion

  //#region Events Handlers
  //#endregion
}
