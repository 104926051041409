import { PAGE } from '@routes/index';

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  context: {
    baseUrl: '/',
    auth: {
      // baseUrl: 'https://idp-stg.tecalliance.services/webcat30/v1/services/WebCat30WS.jsonEndpoint',
      baseUrl: 'https://webservice-preprod.tecalliance.services/webcat30/v1/services/WebCat30WS.jsonEndpoint',
      customErrorsHandling: {
        'getAPIKeyForUser': [ 401, 403 ],
        'refreshCurrentAPIKey': [ 401, 403 ],
        'getCurrentAPIKeyInfo': [ 401, 403 ],
        'deleteCurrentAPIKey': [ 401, 403 ],
        'getComputedUserAttributes': [ 400 ],
      },
      defaultHeaders: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      lifetime: 90,
      autoRefresh: false,
      checkInterval: 15,
      timeout: 30,
      idleTimeout: 0, // idle time control disabled
      homePage: null // We manage the home login manually
    },
    navigation: {
      pages: PAGE,
    },
    clientId: 'IDP Client Viewer',
    language: '',
    defaultLanguage: 'en',
    languages: [],
    debug: true,
    environment: 'local',
    country: 'DE',
    assets: {
      tecdocCDN: {
        baseUrl: 'https://webservice-preprod.tecalliance.services/pegasus-3-0/documents',
        useVersion: false
      },
      tecdocAPI: {
        // baseUrl: 'https://idp-stg.tecalliance.services/pegasus-3-0/services/TecdocToCatDLW.jsonEndpoint',
        baseUrl: 'https://webservice-preprod.tecalliance.services/pegasus-3-0/services/TecdocToCatDLW.jsonEndpoint',
        defaultHeaders: {
          'Content-Type': 'application/json'
        },
      },
    },
    mandator: null,
    defaultMandator: 'tecdocfull',
    defaultMandatorId: 'B2wxvWyWkpWGJgfYwcTA2'
  },
  noImageUrl: 'assets/images/no-image-available.jpg',
};
