import { NavPage } from '@ta/ng-core';

export const PAGE: {[key: string]: NavPage} = {
  /**
   * Login Page params:
   */
  // tslint:disable-next-line:max-line-length
  login: { id: 'login', name: 'LoginPage', route: '/login', historyOptions: { resetHistory: true, resetQueryParams: true, resetFragment: true } },

  /**
   * Home Page params:
   */
  // tslint:disable-next-line:max-line-length
  home: { id: 'home', name: 'HomePage', route: '/home', isHome: true, historyOptions: { resetHistory: true, resetQueryParams: true, resetFragment: true } },

};
