import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AuthService, Guid } from '@ta/ng-core';

import { environment } from '@env/environment';

import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class TecdocInterceptor implements HttpInterceptor {
  //#region Properties

  get SessionID(): string {
    if (!this._sessionID) {
      this._sessionID = Guid.newGuid().toString();
    }

    return this._sessionID;
  }

  //#endregion

  //#region Fields

  private _sessionID?: string;

  //#endregion

  //#region Constructor

  constructor(
    private auth: AuthService,
  ) { }

  //#endregion

  //#region HttpInterceptor Methods

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the TecDoc catalog request to add the new header.
    // HttpClient works with inmutable requests.

    const ignoreErrors = environment.production;

    if (req.url.startsWith(environment.context.assets.tecdocAPI.baseUrl)) {
      return this.checkResponse(
        this.setAPIKey( this.setSessionInfo(req) ),
        next,
        undefined,
        ignoreErrors);
    }

    if (this.auth.isLogged && req.url.startsWith(environment.context.auth.baseUrl)) {
      return this.checkResponse(
        this.setSessionInfo(req),
        next,
        environment.context.auth.customErrorsHandling,
        ignoreErrors );
    }

    return next.handle(req);
  }

  //#endregion

  //#region Helpers

  private setAPIKey(req: HttpRequest<any>): HttpRequest<any> {
    if (this.auth.isLogged) {
      const headers = req.headers.set('X-Api-Key', this.auth.token);

      return req.clone({ headers });
    }

    return req;
  }

  private setSessionInfo(req: HttpRequest<any>): HttpRequest<any> {
    // const context = this.context.getInstance<WebShopContext>();

    let headers = req.headers.set('X-Log-Tracking-Id', this.SessionID);
    headers = headers.delete('Authorization');

    // if (context.mandator) {
    //   headers = headers.set('X-Catalog', context.mandator.id);
    // }

    return req.clone({ headers });
  }

  private checkResponse(
    req: HttpRequest<any>,
    next: HttpHandler,
    customErrorsHandling?: Array<number> | {[key: string]: number[]},
    ignoreErrors = false
  ): Observable<HttpEvent<any>> {
    return next.handle( req )
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const resp = event as HttpResponse<any>;
            this.handleTecDocResponse(resp);
          }
        }),
        catchError((err, caught) => {
          if (err instanceof HttpErrorResponse) {
            this.handleError(req, err, customErrorsHandling, ignoreErrors);
          }

          return throwError( err );
        })
      );
  }

  private handleTecDocResponse(resp: HttpResponse<any>) {
    if (!resp.body?.status) {
      return;
    }

    if (resp.body.status === 500
      && resp.body.statusText
      && (resp.body.statusText as string).toLowerCase().indexOf('catalog user is not active') > -1) {
      throw new HttpErrorResponse({
        error: resp.body.statusText,
        headers: resp.headers,
        status: 401,
        statusText: resp.body.statusText,
        url: resp.url ?? undefined
      });
    }

    if (resp.body.status >= 400) {
      throw new HttpErrorResponse({
        error: resp.body.msgKey || resp.body.statusText,
        headers: resp.headers,
        status: resp.body.status,
        statusText: resp.body.statusText,
        url: resp.url ?? undefined
      });
    }
  }

  private handleError(
    req: HttpRequest<any>,
    err: HttpErrorResponse,
    customErrorsHandling?: Array<number> | {[key: string]: number[]},
    ignoreErrors = false
  ) {
    if (customErrorsHandling) {
      if (Array.isArray(customErrorsHandling)) {
        if (customErrorsHandling.indexOf(err.status) > -1) {
          return false;
        }
      } else {
        for (const callName in customErrorsHandling) {
          // The call name is located in the body as first field.
          if (req.method === 'POST' && req.body && typeof req.body === 'object'
            && req.body.hasOwnProperty(callName)
            && customErrorsHandling[callName].indexOf(err.status) > -1) {
            return false;
          }

          // The call name is located in the url (example: '/user/list').
          if (callName.startsWith('/') && req.url.endsWith(callName)
            && customErrorsHandling[callName].indexOf(err.status) > -1) {
            return false;
          }
        }
      }
    }

    switch (err.status) {
      case 0:
        // if (showError) {
        //   this.growl.unknownError();
        // } else {
        //   console.log('Unknown Error: ', err);
        // }
        break;

      case 400:
        if (!ignoreErrors) {
          alert('Bad request');
        }
        break;

      case 401:
        // this.auth.logout().subscribe();
        alert('Session Expired');
        break;

      case 403:
        if (!ignoreErrors) {
          alert('Forbidden');
        }
        break;

      case 404:
        // Not report this error
        alert('Not found');
        break;

      case 407:
        alert('Unathorized');
        break;

      case 500:
        alert('Intervan server error');
        break;

      default:
        return false;
    }

    return true;
  }

  //#endregion
}
