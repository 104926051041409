<footer>
  <div class="wrap">
    <div class="footer-parts">
      <div class="footer-part footer-part-1">
        <div class="social d-none d-md-inline text-truncate">
          <ng-template ngFor let-item [ngForOf]="socialNetworks" >
            <a *ngIf="item.url" class="nav-link" target="_blank" [href]="item.url">
              <i [class]="item.icon"></i>
            </a>
          </ng-template>
        </div>
        <div class="links links-main text-truncate">
          <!-- 
          <a class="separator" [href]="'CATALOGUE.PRIVACY_STATEMENT_LINK' | translate" target="_blank">
            {{ 'FOOTER.PRIVACY_STATEMENT' | translate }}
          </a>
          <a *ngIf="hasTermsAndConditions" class="separator" href="" (click)="onTermsAndConditionsClick($event)">
            {{ 'FOOTER.TERMS_AND_CONDITIONS' | translate }}
          </a>
          <a class="separator" href="" (click)="onLegalNoticeClick($event)">
            {{ 'FOOTER.LEGAL_NOTICE' | translate }}
          </a>
          <a class="separator separator-only-when-footer-one-row" [href]="contactUrl" [title]="contactUrl" target="_blank">
            {{ 'FOOTER.CONTACT' | translate }}
          </a>
          -->
        </div>
      </div>
    </div>
  </div>
</footer>
