import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  HostListener
} from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { SelectItem } from 'primeng/api';

import { AuthService, AuthResponse, ContextService, StorageService, TaskTrackerService } from '@ta/ng-core';
import { TecdocArticleService, NumberType, CisCommonService, TecdocCommonService } from '@ta/ng-ecommerce-tecdoc';
import { Attribute, GetUserAttributesResponse } from '@ta/ng-ecommerce-trade-module';

import { Observable, of, forkJoin } from 'rxjs';
import { catchError, mergeMap, map, share } from 'rxjs/operators';

@Component({
  selector: 'webcat-app',
  templateUrl: 'webcat-app.html',
})
export class WebcatAppComponent implements OnInit, OnDestroy, AfterViewInit {
  //#region Queries

  @ViewChild('form') form!: NgForm;

  //#endregion

  //#region Fields

  readonly user = {
    username: '',
    password: '',
    hasDebugModeEnabled: false
  };

  readonly query = {
    username: '',
    password: '',
    searchTerm: '',
    exact: true,
    dataSupplierId: undefined,
  };

  resultIDP: any = {};
  resultTAF: any = {};
  comparerSwitch = false;

  viewer: 'raw' | 'json' | 'comparer' = 'json';
  foundArticles: SelectItem[] = [];
  selectedArticle: any;
  fileName: string = '';
  countryName: string = 'DE^^^Germany';
  countries: SelectItem[] = [];
  countryPlaceholder = 'DE - Germany';

  //#endregion

  //#region Properties

  get articleListPlaceholder(): string {
    if (this.foundArticles?.length) {
      return `${this.foundArticles.length} articles found. Choose a specific article...`
    }

    return 'Choose a specific article...';
  }

  //#endregion

  //#region Constructor

  constructor(
    private auth: AuthService,
    private context: ContextService,
    private http: HttpClient,
    private tracker: TaskTrackerService,
    private tecDocArticles: TecdocArticleService,
    private common: TecdocCommonService,
    private storage: StorageService
  ) {
    this.storage.spaceName = 'IDP-JSON-Client';
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {
    this.query.username = this.storage.get('username') ?? '';
    this.query.searchTerm = this.storage.get('searchTerm') ?? '';
  }

  ngOnDestroy() { }

  ngAfterViewInit() {
  }

  //#endregion

  //#region Helpers

  private logIn(loginData: { username: string, password: string }): Observable<Attribute[] | null> {
    const logout$: Observable<AuthResponse | null> = this.auth.isLogged ? this.auth.logout() : of(null);

    return logout$
      .pipe(
        mergeMap(_ => {
          return this.auth
            .login(loginData)
            .pipe(
              catchError((err: HttpErrorResponse, res) => {
                alert(err?.statusText ?? 'User not valid');
                return of(null);
              }),
              mergeMap(result => {
                if (result == null) {
                  return of(null);
                }

                if (result?.status !== 200) {
                  alert(result?.message ?? 'User not valid');
                  return of(null);
                }

                return this.http
                  .post<GetUserAttributesResponse>(
                    `${this.context.instance.auth.baseUrl}`,
                    { getComputedUserAttributes: { keys: ['allow_debug'] } },
                    {
                      headers: new HttpHeaders(this.context.instance.auth.defaultHeaders).set('X-Api-Key', this.auth.token),
                      observe: 'response'
                    }
                  );
              }),
              map(res => res?.body?.attributes ?? null)
            );
        })
      );
  }

  private searchParts(articlesDebug = false) {
    const loginData = {
      username: this.query.username?.trim(),
      password: this.query.password?.trim(),
      catalog: 'tecdocfull' //'idpcatalog'
    };

    const attrs: Attribute[] = [{ key: 'allow_debug', value: `${this.user.hasDebugModeEnabled}` }];

    const logIn$ = this.auth.isLogged
      && loginData.username === this.user.username
      && loginData.password === this.user.password
      ? of(attrs)
      : this.logIn(loginData);

    logIn$
      .subscribe(result => {
        if (!this.auth.isLogged) {
          this.user.hasDebugModeEnabled = false;
          this.resultIDP = [];
          this.foundArticles = [];
          this.query.dataSupplierId = undefined;
          this.selectedArticle = undefined;
          return;
        }

        this.user.username = loginData.username;
        this.user.password = loginData.password;
        this.user.hasDebugModeEnabled = result?.find(attr => attr.key === '')?.value === 'true';

        this.fetchCountries();

        if (articlesDebug) {
          this.fetchArticlesDebug();
        } else {
          this.fetchArticles();
        }
      });
  }

  fetchCountries() {
    this.tracker.add(
      this.common.getCountries({
        lang: 'EN',
        provider: 0
      })
    ).subscribe(result => {
      this.countries = result.data?.array?.map<SelectItem>(item => ({
        label: item.countryCode + ' - ' + item.countryName,
        value: item.countryCode + '^^^' + item.countryName
      })) ?? [];
    });
  }

  fetchArticles() {
    this.tracker.add(
      this.tecDocArticles.getArticles({
        // Search params
        // ------------------------------------------------------------------------
        articleCountry: this.countryName.split('^^^')[0],
        provider: 0,
        lang: 'en',
        searchQuery: this.query.searchTerm,
        searchMatchType: 'prefix_or_suffix', // 'exact', 
        searchType: NumberType.Article,
        page: 1,
        perPage: 100,

        // Data to be included
        // ------------------------------------------------------------------------
        includeAll: true,
        // includeLinkages: options.includeLinkages,
        // linkagesPerPage: 100,
        // linkagesPage: options.linkagesPage,
        // includeGenericArticles: true,
        // includeArticleCriteria: true,
        // includeMisc: true,
        // includeImages: options.includeImages,
        // includePDFs: options.includePDFs,
        // includeLinks: options.includeLinks,
        // includeArticleText: true,
        // includeOEMNumbers: options.includeOeNumbers,
        // includeReplacedByArticles: true,
        // includeReplacesArticles: true,
        // includeComparableNumbers: true,
        // includeGTINs: true,
        // includeTradeNumbers: true,
        // includePrices: options.includePrices,

        // Facets to be included
        // ------------------------------------------------------------------------
        includeDataSupplierFacets: true,
        includeGenericArticleFacets: false,
        includeCriteriaFacets: false
      })
    ).subscribe(result => {
      this.resultIDP = result;
      this.resultTAF = undefined;
      this.viewer = this.viewer === 'comparer' ? 'json' : this.viewer;

      this.foundArticles = result.articles
        ?.map<SelectItem>(item => ({
          label: item.articleNumber + ' [ Brand: ' + item.dataSupplierId + ' ]',
          value: item.articleNumber + '^^^' + item.dataSupplierId
        }
        )) ?? [];
      this.fileName = `${this.query.searchTerm} - (${this.foundArticles?.length} articles found).json`;
    });
  }

  fetchArticlesDebug() {
    const selected = (this.selectedArticle as string).split('^^^');

    const getRequestModel = (dataSource: 'IDP' | 'TAF') => {
      return {
        getArticleDebug: {
          arg0: {
            dataSupplierId: selected[1],
            articleNumber: selected[0],
            dataSource: dataSource
          }
        }
      }
    };

    const fetchFromIDP$ = this.http
      .post<any>(
        `${this.context.instance.assets?.['tecdocAPI'].baseUrl}`,
        getRequestModel('IDP'),
        {
          headers: new HttpHeaders(this.context.instance.assets?.['tecdocAPI'].defaultHeaders).set('X-Api-Key', this.auth.token),
          observe: 'response'
        }
      ).pipe(
        catchError(_ => of({ body: {} }))
      );

    const fetchFromTAF$ = this.http
      .post<any>(
        `${this.context.instance.assets?.['tecdocAPI'].baseUrl}`,
        getRequestModel('TAF'),
        {
          headers: new HttpHeaders(this.context.instance.assets?.['tecdocAPI'].defaultHeaders).set('X-Api-Key', this.auth.token),
          observe: 'response'
        }
      ).pipe(
        catchError(_ => of({ body: {} }))
      );

    this.tracker.add(
      forkJoin([fetchFromIDP$, fetchFromTAF$ ]).pipe(share())
    ).subscribe(([ resultIDP, resultTAF ]) => {
      this.resultIDP = resultIDP.body;
      this.resultTAF = resultTAF.body;
      this.fileName = `Article No [${selected[0]}] - Data Supplier [${selected[1]}].json`;
    });
  }

  //#endregion

  //#region Events Handlers

  onSearch(articlesDebug = false) {
    this.form.controls['username'].markAsDirty();
    this.form.controls['password'].markAsDirty();
    this.form.controls['searchTerm'].markAsDirty();

    if (!this.form.valid) {
      return;
    }

    this.storage.set('username', this.query.username);
    this.storage.set('password', this.query.password);
    this.storage.set('searchTerm', this.query.searchTerm);

    this.searchParts(articlesDebug);
  }

  onJSONViewerClick() {
    this.viewer = 'json';
  }

  onRawClick() {
    this.viewer = 'raw';
  }

  onCompareClick() {
    this.viewer = 'comparer';
  }

  onSave() {
    if (!this.resultIDP) {
      return;
    }

    const a = document.createElement("a");
    const file = new Blob([JSON.stringify(this.resultIDP, null, 2)], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = this.fileName;
    a.click();
  }

  onComparerSwitch() {
    this.comparerSwitch = !this.comparerSwitch;
  }

  @HostListener('window:beforeunload')
  onBeforeunload() {
    this.auth.logout().subscribe();
  }

  //#endregion
}
