<header>
  <div class="wrap">
    <div class="header-top d-flex">
      <div #logo class="logo">
        <a href="https://www.tecalliance.net/en" target="_black">
          <img [src]="'assets/images/logo-tecalliance-80.svg'" alt="logo">
        </a>
      </div>
      <div class="claim visible-from-1200">IDP Client Viewer</div>
      <div class="search">
        <div class="flex-grow-1 ng-star-inserted" >
          <!--
            <input-search [focus]="focus"
              [(ngModel)]="searchQuery"
              [productGroupTargetType]="page.targetParams?.targetType"
              [productGroupTargetId]="page.targetParams?.targetId"
              (search)="onSearch()">
            </input-search>
          -->
        </div>
      </div>
      <div class="top-nav">
      </div>
    </div>
  </div>
</header>
