import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';

import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

import {
  AuthService,
} from '@ta/ng-core';

@Component({
  selector: 'webcat-header',
  templateUrl: 'webcat-header.html',
  styleUrls: ['webcat-header.scss'],
  animations: [
    trigger('sideMenu', [
      state('expanded', style({
        opacity: '*'
      })),
      state('collapsed', style({
        opacity: '0',
        visibility: 'hidden'
      })),
      transition('expanded => collapsed', [animate('300ms ease-out')]),
      transition('collapsed => expanded', [animate('300ms ease-out')])
    ]),
    trigger('bodySideMenu', [
      state('expanded', style({
        width: '*',
      })),
      state('collapsed', style({
        width: '0',
      })),
      transition('expanded => collapsed', [animate('300ms ease-out')]),
      transition('collapsed => expanded', [animate('300ms ease-out')])
    ])
  ]
})
export class WebcatHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  //#region Queries
  //#endregion

  //#region Properties
  //#endregion

  //#region Fields
  //#endregion

  //#region Constructor

  constructor(
    public auth: AuthService,
  ) {
  }

  //#endregion

  //#region Lifecycle Hooks

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
  }

  //#endregion

  //#region Methods
  //#endregion

  //#region Helpers
  //#endregion

  //#region Helpers
  //#endregion

  //#region Events Handlers
  //#endregion
}
