import { UserDetail } from '@ta/ng-core';

/**
 * Web Show user model.
 */
export class WebShopUser extends UserDetail {
  //#region Fields

  catalogUserId?: string;
  company?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  fax?: string;

  login?: string;

  //#endregion

  //#region Constructor

  constructor() {
    super();
  }

  //#endregion

  //#region Static methods

  /**
   * Web Shop user factory.
   *
   * @param {{key: string, value: string}[]} data
   */
  static factory<T extends UserDetail>(data?: {key: string, value: string}[]): T {
    const user_detail = new WebShopUser();

    // user_detail.username = AttributeReaders.getString(data, USER_PROPERTIES.USERNAME);

    // User Profile
    // user_detail.id = AttributeReaders.getString(data, USER_PROPERTIES.USER_ID);
    // user_detail.catalogUserId = AttributeReaders.getString(data, USER_PROPERTIES.CATALOG_USER_ID);
    // user_detail.email = AttributeReaders.getString(data, USER_PROPERTIES.USER_EMAIL);
    // user_detail.firstName = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_FIRST_NAME);
    // user_detail.lastName = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_LAST_NAME);
    // user_detail.company = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_COMPANY);
    // user_detail.address1 = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_ADDRESS1);
    // user_detail.address2 = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_ADDRESS2);
    // user_detail.city = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_CITY);
    // user_detail.state = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_STATE);
    // user_detail.zip = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_ZIP);
    // user_detail.country = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_COUNTRY);
    // user_detail.phone = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_PHONE);
    // user_detail.fax = AttributeReaders.getString(data, USER_PROPERTIES.PROFILE_FAX);

    return user_detail as any;
  }

  //#endregion
}
