import { AppContext } from '@ta/ng-core';
import { environment } from '@env/environment';


export class WebShopContext extends AppContext {
  /**
   * Default mandator.
   */
  defaultMandator?: string;

  /**
   * Default mandator ID.
   */
  defaultMandatorId?: string;

  get STORAGE_LANGUAGE(): string {
    return 'UTVPSJYHGZFEMIWQOXRCBKNLAD';
  }

  get STORAGE_ACCESS(): string {
    return 'MZTEIPFWKVCUBSXLGRJDQYOHAN';
  }

  get STORAGE_REFRESH(): string {
    return 'AMXZCIPHLJOKVFBUQTYRNDGSWE';
  }

  get STORAGE_EXPIRATION(): string {
    return 'XAGKUOTMFVIDESNWRPYZBJHCLQ';
  }

  get STORAGE_USERDETAIL(): string {
    return 'TEHVOKARLQICXUSBGJMZPNDFWY';
  }

  /**
   * Web Shop context factory.
   *
   * @param {any} data
   */
  static factory(): Promise<WebShopContext> {
    const factoryFn = (resolve: (arg0: WebShopContext) => void) => {
      const context = Object.assign(new WebShopContext(), environment.context);
      resolve( context );
    };

    return new Promise<WebShopContext>(factoryFn);
  }
}
