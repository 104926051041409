<div taskReporter></div>

<div class="d-flex flex-column flex-nowrap h-100">
  <webcat-header></webcat-header>
  <div class="flex-grow-1 position-relative">
    <div class="ta-inner-body">
      <div class="d-flex flex-row h-100 align-items-stretch">
        <div class="p-3" [style.minWidth.px]="340" [style.width.px]="360" [style.background]="'rgba(0,0,0,.05)'">
          <form #form="ngForm" class="d-flex flex-column h-100">
            <div class="flex-grow-1">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">User&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;Pwd</span>
                </div>
                <input #username="ngModel" type="text" name="username" class="form-control" [(ngModel)]="query.username" required>
                <input #passwrod="ngModel" type="password" name="password" autocomplete="on" class="form-control"
                  [(ngModel)]="query.password" required>
              </div>
              <div class="invalid-feedback"
                [class.d-block]="(username.invalid && username.dirty) || (passwrod.invalid && passwrod.dirty)">
                Please provide a valid user and password.
              </div>
              <div class="input-group mt-3">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="inputGroup-sizing-default">Search Term</span>
                </div>
                <input #searchterm="ngModel" type="text" name="searchTerm" class="form-control" [(ngModel)]="query.searchTerm" required
                  minlength="4">
              </div>
              <div class="invalid-feedback" [class.d-block]="searchterm.invalid && searchterm.dirty">
                Please provide a valid search term.
              </div>
  
              <!--
              <p-dropdown styleClass="w-100 mt-3" name="dataSupplierId" placeholder="Select a data supplier..."
                [options]="dataSupplierFacets" [(ngModel)]="query.dataSupplierId" [virtualScroll]="true" [itemSize]="30">
              </p-dropdown> 
              -->
              <p-dropdown *ngIf="countries?.length" styleClass="w-100 mt-3" name="countryId" [placeholder]="countryPlaceholder"
                [options]="countries" [(ngModel)]="countryName" [virtualScroll]="true" [itemSize]="30">
              </p-dropdown>
  
              <button type="button" class="btn btn-outline-secondary w-100 mt-3" (click)="onSearch()">Search</button>
  
              <p-dropdown *ngIf="foundArticles?.length"
                styleClass="w-100 mt-3"
                name="articleId"
                [placeholder]="articleListPlaceholder"
                [options]="foundArticles" [(ngModel)]="selectedArticle" [virtualScroll]="true" [itemSize]="30">
              </p-dropdown>
  
              <button *ngIf="foundArticles?.length" type="button"
                [disabled]="selectedArticle == null"
                class="btn btn-outline-secondary btn-block mt-3"
                (click)="onSearch(true)">
                Debug
              </button>
            </div>
            <div>
              <button [disabled]="!foundArticles?.length" type="button"
                class="btn btn-outline-secondary btn-block"
                (click)="onSave()">
                <i class="fa fa-floppy-o"></i> Save
              </button>
              <div class="btn-group btn-block mt-3" role="group">
                <button type="button" class="btn btn-secondary w-50" [class.active]="viewer == 'json'" (click)="onJSONViewerClick()">
                  JSON Viewer
                </button>
                <button type="button" class="btn btn-secondary w-50" [class.active]="viewer == 'raw'" (click)="onRawClick()">
                  Raw JSON
                </button>
                <button type="button" class="btn btn-secondary w-50" *ngIf="resultIDP && resultTAF" [class.active]="viewer == 'comparer'" (click)="onCompareClick()">
                  Compare
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="flex-grow-1">
          <div [style.width.%]="100" [style.height.%]="100" [style.overflow]="'auto'">
            <ngx-json-viewer *ngIf="viewer === 'json'" [json]="resultIDP"></ngx-json-viewer>
            <pre *ngIf="viewer == 'raw'">{{ resultIDP | json }}</pre>
            <div class="d-flex flex-column h-100" *ngIf="viewer === 'comparer'">
              <div class="d-flex border bg-secondary text-white">
                <h4 class="text-center flex-fill"> {{ !comparerSwitch ? 'IDP' : 'TAF' }} </h4>
                <div class="btn-group btn-group-sm">
                  <button class="btn btn-sm btn-light" (click)="onComparerSwitch()">Switch</button>
                </div>
                <h4 class="text-center flex-fill"> {{ !comparerSwitch ? 'TAF' : 'IDP' }} </h4>
              </div>
              <div class="d-flex align-items-stretch flex-grow-1 overflow-auto position-relative">
                <differentiate
                  style="max-height: 'inherit';"
                  [onlyShowDifferences]="false"
                  [attributeOrderIsImportant]="false"
                  [allowRevert]="false"
                  [allowAdvance]="false"
                  [leftSideToolTip]="'IDP'"
                  [rightSideToolTip]="'TAF'"
                  [leftSideObject]="!comparerSwitch ? resultIDP : resultTAF" 
                  [rightSideObject]="!comparerSwitch ? resultTAF : resultIDP">
                </differentiate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <webcat-footer></webcat-footer>
</div>