import {
  ErrorHandler,
  NgModule,
} from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';

import { DifferentiateModule } from '@sedeh/differentiate';

import { TecdocCoreModule } from '@ta/ng-ecommerce-tecdoc';

import { TaTooltipModule } from '@ta/primeng';

import {
  AuthTypes,
  TaCoreModule,
  LazyLoadErrorHandler,
} from '@ta/ng-core';
import { TaNgComponentsModule } from '@ta/ng-components';

import { TaNgLayoutTemplatesModule } from '@ta/ng-layout-templates';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { environment } from '@env/environment';

import { BootstrapRoutingModule } from './bootstrap.routing.module';
import { WebcatAppComponent } from './components/webcat-app/webcat-app';
import { WebcatFooterComponent } from './components/webcat-footer/webcat-footer';
import { WebcatHeaderComponent } from './components/webcat-header/webcat-header';
import { WebShopUser } from './models/web-shop-user';
import { WebShopContext } from './models/web-shop-context';

import { TecdocInterceptor } from './interceptors/tecdoc';

@NgModule({
  bootstrap: [WebcatAppComponent],
  declarations: [
    WebcatAppComponent,
    WebcatFooterComponent,
    WebcatHeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    NgxJsonViewerModule,
    DropdownModule,
    DifferentiateModule,

    TaCoreModule.forRoot({
      authType: AuthTypes.Eluvio,
      contextFactory: WebShopContext.factory,
      userFactory: WebShopUser.factory
    }),
    TaNgLayoutTemplatesModule.forRoot(),
    TaNgComponentsModule,

    // eCommerce
    TecdocCoreModule.forRoot({ services: environment.context.assets.tecdocAPI }),

    // PrimeNg
    ConfirmDialogModule,
    CheckboxModule,
    DialogModule,
    MessagesModule,
    ToastModule,
    TaTooltipModule,

    // Portal
    BootstrapRoutingModule
  ],
  providers: [
    // Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TecdocInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: LazyLoadErrorHandler,
    }
  ]
})
export class BootstrapModule {
}
